import { h } from 'preact'
// import { useState, useEffect } from 'preact/hooks'
import { Router } from 'preact-router'

// Code-splitting is automated for routes
import Index from '../routes/index'
import Diff from '../routes/diff'

export default function App () {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  // const handleRoute = (evt) => {
  //   console.log('Route change:', evt)
  // };

  return (
    <div id="app">
      <Router /*onChange={handleRoute}*/>
        <Index path="/" />
        <Diff path="/diff" />
      </Router>
    </div>
  )
}
